@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

.accordion-summary {
    background-image: url("../../../assets/images/apetixer_deal.png");
    height: 8rem;
    line-height: 1.2;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 1);
}

.accordion-summary-1 {
    background-image: url("../../../assets/images/vegetarian.png");
}

.accordion-summary-2 {
    background-image: url("../../../assets/images/seafoods.png");
}

.accordion-summary-3 {
    background-image: url("../../../assets/images/desi-chats.png");
}

.accordion-summary-4 {
    background-image: url("../../../assets/images/boneless.png");
}

.appetizers-deal-accordion-active {
    font-family: "Kaushan Script";
    color: #fff;
    text-align: center;
    -webkit-text-stroke: 4px;
    -webkit-text-stroke-color: #FEB905;
    font-size: 5rem;
    font-weight: 900;
    letter-spacing: 2px;
    z-index: 0;
    -webkit-text-fill-color: #fff;
    /* margin-bottom: 0; */
}

.appetizers-deal-accordion {
    font-family: "Kaushan Script";
    color: #fff;
    text-align: center;
    z-index: 0;
    font-size: 3.5rem;
    letter-spacing: 1.5px;
    -webkit-text-fill-color: #fff;
    /* margin-bottom: 0; */
}

.appetizers-deal-accordion-2 {
    font-family: "kite One";
    color: #FEB905;
    text-align: center;
    font-size: 1.25rem;
    letter-spacing: 1.5px;
    margin-bottom: 0;
}

@media screen and (max-width:1024px) {
    .appetizers-deal-accordion {
        font-size: 2.5rem;
        letter-spacing: 1px;
    }

    .appetizers-deal-accordion-active {
        -webkit-text-stroke: 2px;
        -webkit-text-stroke-color: #FEB905;
        font-size: 3rem;
        letter-spacing: 1.5px;
        /* margin-bottom: 0; */
    }

    .appetizers-deal-accordion-2 {
        font-size: 0.9rem;
        letter-spacing: 0px;
    }

    .accordion-summary {
        height: 6rem;
    }
}

@media screen and (max-width:768px) {
    .appetizers-deal-accordion {
        font-size: 2.5rem;
        letter-spacing: 1.5px;
    }

    .appetizers-deal-accordion-active {
        font-family: "Kaushan Script";
        color: #fff;
        text-align: center;
        -webkit-text-stroke: 2px;
        -webkit-text-stroke-color: #FEB905;
        font-size: 3rem;
        letter-spacing: 1.5px;
        /* margin-bottom: 0; */
    }

    .appetizers-deal-accordion-2 {
        font-size: 0.8rem;
        letter-spacing: 1.5px;
    }

    .accordion-summary {
        height: 6rem;
    }
}

@media screen and (max-width:425px) {
    .appetizers-deal-accordion {
        font-size: 2rem;
        letter-spacing: 1px;
    }

    .appetizers-deal-accordion-active {
        -webkit-text-stroke: 1px;
        -webkit-text-stroke-color: #FEB905;
        font-size: 2rem;
        letter-spacing: 1.5px;
        /* margin-bottom: 0; */
    }

    .appetizers-deal-accordion-2 {
        font-size: 0.8rem;
        letter-spacing: 0px;
    }

    .accordion-summary {
        height: 5rem;
    }
}