@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kite+One&display=swap');


.menu-items-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 30px;
}

.menu-items-card img {
    border-radius: 10px;
    width: 100px;
    height: 100px;
}

.card-content {
    margin-left: 10px;
    flex-grow: 1;
    overflow: hidden;
}

.card-header {
    color: #FEB905;
    font-size: 0.975rem;
    font-family: "Lato";
    font-weight: 600;
    padding: 0 !important;
    background-color: #fff !important;
    border-bottom: none !important;
    z-index: 0;
}

.menu-items {
    font-family: "Kite One";
    font-size: 0.625rem;
    z-index: 0;
}

.add-to-card-btn {
    background-color: #FFA500;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.25rem 0.7rem;
    cursor: pointer;
    font-size: 0.7rem;
    font-family: "Kite One";
    border-radius: 25px;
    font-weight: bold;

}

.card-actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap !important;
    gap: 10px;
}

.card-actions span {
    font-size: 0.8rem;
    font-weight: bold;
    color: #fff;
    border-radius: 25px;
    padding: 0.25rem 0.7rem;
    background-color: #000;
}

.heart-icon {
    margin-left: auto;
    cursor: pointer;
    font-size: 1.5em;
    color: #000;
}

.heart-icon:hover {
    color: #ff0000;
}