.reserved-section {
    background-image: url('../../../assets/images/reserved-banner.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    position: relative;
    z-index: 0;
}

.events-catering-text {
    font-family: "Lemon";
    font-size: 4.3rem;
    color: transparent;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    letter-spacing: 0.5px;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #fff;
    padding: 10rem 8rem;
}

.events-celebration-section {
    background-image: url("../../../assets/images/events-celebration-section.png");
    background-size: cover;
    background-position: top;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.enquery-section {
    background-image: url("../../../assets/images/enquery-form-bg.png");
    background-size: cover;
    background-position: top;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.custom-input-styles {
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid #fff !important;
    color: #fff !important;
    border-radius: 0 !important;
}

.custom-select-styles,
.custom-select-styles:focus {
    background-color: transparent;
    border: none !important;
    border-bottom: 1px solid #fff !important;
    color: #fff !important;
    border-radius: 0 !important;
    box-shadow: none;
}

.custom-select-styles option {
    background-color: #fff;
    color: #000;
}

.form-check-input:checked {
    border-color: #FEB905;
    background-color: #FEB905
}

.enquery-form-text {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 4rem;
    font-family: "Kaushan Script";
    font-weight: 400;
    font-style: normal;
    color: #fff;
}

.custom-form-label {
    color: #fff;
    font-family: "Kite One";
    font-size: 1.25rem;
    font-weight: 700;
    text-align: start;
    margin-top: 1rem;
}

.events-celebration-text {
    font-size: 4.5rem;
    font-family: "Kaushan Script";
    font-weight: 400;
    font-style: normal;
    margin-bottom: 0;
    z-index: 0;
}

.with-cafe-raja-text {
    font-family: "Lemon";
    font-style: normal;
    font-size: 3.5rem;
    z-index: 0;
    text-align: center;
    color: rgba(0, 0, 0, 0);
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #FEB905;
    line-height: 4rem;
}

.our-events-text {
    font-size: 4.35rem;
    font-family: "Kaushan Script";
    font-weight: 400;
    font-style: normal;
    margin-bottom: 0;
    text-align: center;
}

.catering-text {
    font-family: "Lemon";
    font-style: normal;
    font-size: 3.5rem;
    text-align: center;
    color: rgba(0, 0, 0, 0);
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: #FEB905;
    line-height: 4rem;
}



.events-celebration-container {
    padding: 10rem;
}

.our-events-description {
    color: #fff;
    margin-top: 3rem;
}

/* EventsCatering.css */

.bg-white {
    background-color: #fff !important;
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.rounded {
    border-radius: .25rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.text-center {
    text-align: center !important;
}

.btn-warning {
    background-color: #ffcc00;
    border: none;
    color: #fff;
}

.social-icons {
    font-size: 1.5rem;
}

.fa {
    cursor: pointer;
}

.fa-facebook:hover {
    color: #3b5998;
}

.fa-twitter:hover {
    color: #00acee;
}

.fa-instagram:hover {
    color: #e1306c;
}

.profile-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.profile-details-container {}

.profile-header {
    color: #FEB905;
    font-family: "Kaushan Script";
    font-size: 1.875rem;
    font-weight: 400;
}

.profile-name {
    color: #fff;
    font-family: "Kite One";
    font-size: 1.25rem;
    font-weight: 700;
    text-align: start;
    margin-top: 1rem;
    margin-bottom: 10px;
    z-index: 0;
}

.profile-number {
    color: #fff;
    font-family: "Kite One";
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 10px;
    text-align: start;
    z-index: 0;
}

.profile-mail {
    color: #fff;
    font-family: "Kite One";
    font-size: 1.25rem;
    margin-bottom: 10px;
    font-weight: 400;
    text-align: start;
    z-index: 0;
}

.events-buttons-container {}

.events-button {
    font-family: "Lemon";
    font-size: 1.125rem;
    color: rgb(0, 0, 0);
    background-color: rgb(254, 185, 5);
    border-radius: 50px;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    width: 100%;
    border-bottom: 6px solid rgb(255, 255, 255);
}

.submit-button {
    font-family: "Lemon";
    font-size: 1.125rem;
    color: rgb(0, 0, 0);
    background-color: rgb(254, 185, 5);
    border-radius: 50px;
    width: fit-content;
    padding: 0.5rem 2rem;
    border-bottom: 6px solid rgb(255, 255, 255);
    margin-bottom: 2rem;
}

.our-events-section {
    background-color: rgba(0, 0, 0, .2);
}

.our-events-image-section {
    background-image: url('../../../assets/images/our-events.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    padding: 2.5rem;
    background-color: rgba(0, 0, 0, 0.9);
    position: relative;
    z-index: 0;
}


@media screen and (max-width:1440px) {


    .events-catering-text {
        font-size: 4rem;
        padding: 6rem 0rem;
        -webkit-text-stroke-width: 3px
    }

}

@media screen and (max-width:1024px) {
    .events-celebration-container {
        padding: 7rem;
    }

    .events-catering-text {
        font-size: 3rem;
        padding: 4rem 0rem;
        -webkit-text-stroke-width: 2px
    }

    .profile-number {
        font-size: 1rem;
    }

    .profile-mail {
        font-size: 1rem;
    }

    .events-button {
        font-size: 1rem;
        padding: 0.4rem 0.8rem;
    }

    .enquery-form-text {
        font-size: 3.5rem;
    }
}


@media screen and (max-width:768px) {

    .events-celebration-text {
        font-size: 3.5rem;
    }

    .our-events-text {
        font-size: 3.5rem;
    }

    .catering-text {
        font-size: 2.5rem;
        -webkit-text-stroke-width: 2px;
        line-height: 4rem;
    }

    .with-cafe-raja-text {
        font-size: 2.5rem;
        -webkit-text-stroke-width: 2px;
        line-height: 2rem;
    }

    .enquery-form-text {
        font-size: 3rem;
    }

    .events-catering-text {
        font-size: 3rem;
        padding: 2rem;
        -webkit-text-stroke-width: 2px
    }
}

@media screen and (max-width:425px) {
    .events-celebration-container {
        padding: 3.5rem;
    }

    .enquery-form-text {
        font-size: 3rem;
    }

    .events-catering-text {
        font-size: 2rem;
        padding: 0rem;
        -webkit-text-stroke-width: 1.5px
    }

    .profile-container {
        flex-direction: column;
    }

    .events-celebration-text {
        font-size: 2.5rem;
    }

    .with-cafe-raja-text {
        font-size: 1.8rem;
        -webkit-text-stroke-width: 2px;
        line-height: 1rem;
    }
}