.Display {
  width: 100%;
  height: 30%;
  background-color: #323232;
  font-size: 60px;
  color: white;
  display: flex;
  align-items: flex-end;
  overflow-x: auto;
}

.DisplaySpan {
  margin: 16px;
}
