.faq-container {
    margin: 4rem auto;
    padding: 20px;
}

.faq-container h2 {
    color: #fff;
}

.faq-item {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.faq-question {
    background-color: #4A144F;
    color: #fff;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-answer {
    padding: 15px;
    background-color: #f4f4f4;
    color: #333;
    font-size: 14px;
}