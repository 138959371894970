@import url('https://fonts.googleapis.com/css2?family=Kite+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lemon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Milonga&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lemon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oleo+Script:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lily+Script+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Khand:wght@300;400;500;600;700&display=swap');

body {
  background-image: url("../../../assets/images/body_bg_image.jpeg");
}

/* .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
} */

.navbar-light .navbar-toggler {
  color: transparent !important;
}

a:hover {
  color: blue !important;
}



.overflow-to-select-active-branch {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1052;
  display: block;
  background: rgba(255, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overflow-to-select-active-branch .selector-box {
  background: white;
  width: 300px;
  min-height: 200px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overflow-to-select-active-branch .selector-box h5 {
  font-size: 18px;
  margin-bottom: 20px;
}

.overflow-to-select-active-branch .selector-box select {
  min-width: 200px;
  padding: 10px;
  border-radius: 5px;
}


.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #FFFFFF;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
  font-family: "Kite One";
  font-weight: normal;
  font-size: 1.3rem;
  letter-spacing: 1.5px;
  margin: 0rem 2rem;
}

@media screen and (max-width:1024px) {
  .navbar-light .navbar-nav .nav-link {
    font-size: 1rem;
  }

}

@media screen and (max-width:768px) {
  .navbar-light .navbar-nav .nav-link {
    font-size: 1.5rem;
    font-weight: 900;
  }

  .navbar-nav {
    padding: 1rem;
    height: 100vh;
    overflow: auto;
  }
}

.flavor-rich-text {
  font-family: "Lemon";
  font-size: 7.5rem;
  color: transparent;
  font-weight: 400;
  font-style: normal;
  text-align: end;
  letter-spacing: 0.5px;
  -webkit-text-stroke-width: 5px;
  -webkit-text-stroke-color: #fff;
}

.served-with-pession-text {
  font-family: "Milonga";
  font-weight: 400;
  letter-spacing: 0.5px;
  font-style: normal;
  color: #FEB905;
  text-align: end;
  font-size: 5.25rem;

}

.order-online-image {
  position: relative;
  padding: 4rem;
}

.delicious-tasty-text {
  font-family: "Kite One";
  font-size: 2.31rem;
  color: #fff;
  transform: rotate(-30deg);
  position: absolute;
  right: 7rem;
  bottom: 4rem;
}


.menu-card-container {
  align-items: center;
  justify-content: center;
  row-gap: 10px;
}

.menu-card {
  background-image: url("../../../assets/images/our-menu-bg.png");
  border-radius: 20px;
  height: 13rem;
}

.menu-card-text {
  font-family: "Lemon", serif;

  font-weight: 400;
  font-style: normal;
  font-size: 1.56rem;
  color: #5A5151;
  height: 100%;
  letter-spacing: 0.5px;
  text-align: center;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
}

.menu-card-text:hover {
  color: #fff !important;
}

.menu-card-text-1 {
  background-color: rgba(255, 154, 3, 0.7);
}

.menu-card-text-2 {
  background-color: rgba(173, 172, 171, 0.7);
}

.menu-card-text-3 {
  background-color: rgba(143, 166, 0, 0.7);
  /* Light blue */
}

.menu-card-text-4 {
  background-color: rgb(248, 232, 95, 0.7);
  /* Light orange */
}

.menu-card-text-5 {
  background-color: rgb(255, 154, 3, 0.7);
}

@media screen and (max-width:1440px) {
  .flavor-rich-text {
    font-size: 6rem;
  }

  .menu-card-container {
    padding-top: 2rem;
  }

  .served-with-pession-text {
    font-size: 4rem;
  }

  .delicious-tasty-text {
    bottom: 0;
    right: 4rem;
    font-size: 1.7rem;
  }

  .order-online-image {
    padding: 0rem;
  }

}

@media screen and (max-width:1024px) {
  .flavor-rich-text {
    font-size: 4rem;
  }

  .served-with-pession-text {
    font-size: 3rem;
  }

  .menu-card-text {
    font-size: 1.2rem;
  }

  .delicious-tasty-text {
    position: unset;
    transform: rotate(0deg);
    font-size: 2rem;
    bottom: -11%;
    text-align: center;
    width: 100%;
  }

  .menu-card {
    height: 10rem;
  }

  .order-online-image {
    padding: 1rem;
  }
}

@media screen and (max-width:768px) {
  .flavor-rich-text {
    font-size: 4.5rem;
    text-align: center;
  }

  .menu-card {
    height: 6rem;
  }

  .menu-card-text {
    font-size: 1.8rem;
  }

  .served-with-pession-text {
    font-size: 3.5rem;
    text-align: center;
  }

  .delicious-tasty-text {
    position: unset;
    transform: rotate(0deg);
    font-size: 2rem;
    bottom: -11%;
    text-align: center;
    width: 100%;
  }

  .order-online-image {
    padding: 0rem;
  }
}

@media screen and (max-width:425px) {
  .menu-card {
    height: 5rem;
  }

  .flavor-rich-text {
    font-size: 2rem;
    text-align: center;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #fff;
  }

  .served-with-pession-text {
    font-size: 1.5rem;
    text-align: center;
  }

  .menu-card-text {
    font-size: 1rem;
  }

  .menu-card-container {
    padding-top: 0;
  }

  .order-online-image {
    padding: 2.5rem;
  }
}



.footer-heading a,
.footer-heading p {
  color: #fff;
  font-variant: all-small-caps;
  font-size: 1.3rem;
  width:fit-content;
  font-family: "Kite One";
  padding: .01rem;
  margin-bottom: 0px;
}

.kh-floating-cart {
  background-color: #FEB905 !important;
}

.kh-floating-cart__text {
  color: #000 !important;
}

.cart {
  position: relative;
}

.cart-counter {
  background-color: rgba(254, 185, 5, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  padding: 0.2rem 0.6rem;
  border-radius: 50%;
}

.catering-section {
  background-image: url('../../../assets/images/bg_image.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 3.5rem !important;
  z-index: 0;
}


@media screen and (max-width:1024px) {
  .celebration-text {
    font-size: 3.7rem;
    width: fit-content;
  }
}

@media screen and (max-width:768px) {
  .celebration-text {
    font-size: 3.7rem;
    width: fit-content;
  }
}


.overlay-text {
  position: absolute;
  left: 6rem;
  top: 50%;
  transform: translateY(-50%) rotate(-10deg) scale(1.1);
  /* Rotates the text */
  font-weight: bold;
}

.custom-text {
  color: #fff;
  font-size: 1.87rem;
  font-weight: 400;
  font-family: "Kite One";
  text-transform: none;
}

.highlight {
  color: rgba(0, 0, 0, .1);
  -webkit-text-stroke-width: 5px;
  -webkit-text-stroke-color: #fff;
  font-size: 4.3rem;
  /* Adjust font size */
  font-weight: 900;
  font-family: "Luckiest Guy", cursive;
  font-weight: 400;
  font-style: normal;
}

.unique {
  color: #FEB905;
  font-size: 4rem;
  font-weight: 600;
}

.special {
  color: #fff;
  font-size: 4.3rem;
  font-family: "Lily Script One";
  font-weight: 400;
  font-style: normal;
}


.info-box {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  border-radius: 30px;
  font-family: "Kite One";
}

.info-box p {
  color: #fff;
}




@media screen and (max-width:1024px) {
  .overlay-text {
    transform: translate(-22%, -50%) rotate(-20deg) scale(1);
  }

  .info-box {
    width: 100%
  }

  .catering-section {
    padding: 3rem;
  }
}

@media screen and (max-width:768px) {
  .overlay-text {
    position: unset;
    width: fit-content;
    transform: translate(40%, -10%) rotate(-20deg) scale(1.1);
  }

  .info-box {
    margin-top: 5.5rem;
  }

  .custom-text {
    font-size: 2rem;
  }

  .highlight {
    font-size: 3rem;
  }

  .unique {
    font-size: 3rem;
  }

  .special {
    font-size: 3.1rem;
  }
}

@media screen and (max-width:425px) {

  .overlay-text {
    position: unset;
    transform: translate(0%, -12%) rotate(-20deg);
    width: fit-content;
  }

  .info-box {
    margin-top: 3.5rem;
  }

  .custom-text {
    font-size: 2rem;
  }

  .highlight {
    font-size: 3rem;
    -webkit-text-stroke-width: 2px;
  }

  .unique {
    font-size: 3rem;
  }

  .special {
    font-size: 3.1rem;
  }
}


.contact-info strong {
  color: #FEDB18;
}

.venues-section {
  background-image: url('../../../assets/images/our-venues.png');
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  color: white;
  background-color: rgba(0, 0, 0, .6);
  padding: 4rem 2rem 0 2rem;
}

/* @media screen and (max-width:768px) {
  .venues-section {
    height: 50rem;
  }
} */

.carousel-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
  top: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  background-color: transparent;
  border: none;
}

.carousel-control-prev {
  left: -7rem !important;
}

.carousel-control-next {
  right: -7rem !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black;
  border-radius: 50%;
  width: 50px !important;
  height: 50px !important;
}

.card-container {
  display: flex;
  justify-content: space-around;
  align-items: end;
  flex-wrap: wrap;
  row-gap: 10px;
}


.card-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: all 0.3s ease;
}

.card-custom:nth-child(odd) {
  height: 8rem;
}

.card-custom:nth-child(even) {
  height: 9rem;
}

.card-custom:nth-child(3n+1) {
  background-color: rgba(254, 185, 5, 0.4);
}

.card-custom:nth-child(3n+2) {
  background-color: rgba(255, 255, 255, 0.4);
}

/* .card-custom:nth-child(3n+3) {
  background-color: rgba(254, 185, 5, 0.4);
} */
.card-custom {
  background-color: rgba(254, 185, 5, 0.4);
}

.card-custom-text {
  text-align: center;
  font-family: 'Lemon';
  color: #fff;
  font-size: 1.125rem;
}

@media screen and (max-width:768px) {
  .card-custom-text {
    font-size: 1.2rem;
  }

  .card-container {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .card-custom:nth-child(odd) {
    height: 5rem;
  }

  .card-custom:nth-child(even) {
    height: 6rem;
  }
}

@media screen and (max-width:425px) {

  .card-custom:nth-child(odd) {
    height: 4rem;
  }

  .card-custom:nth-child(even) {
    height: 5rem;
  }

  .venues-section {
    padding: 2rem;
  }
}

.header-container {
  background-color: #000;
  padding: 0 5rem;
}

.header-container-mobile {
  display: none;
}

.menu-for-mobile {
  display: none;
}

@media screen and (max-width:1024px) {
  .header-container {
    padding: 0;
  }
}

.navbar-logo {
  width: 140px;
  display: inline-block;
  transition: all 0.3s ease;
  text-decoration: none;
}

@media screen and (max-width: 576px) {
  .navbar-logo {
    width: 50%;
    margin: auto;
  }
}

.deliver-container {
  display: flex;
  align-items: center;

}

.deliver-image {
  width: 2.063rem;
  height: 2.063rem;
  padding-right: 0.5rem;
}

.deliver-text {
  color: #fff;
  font-family: "Kite One";
  font-weight: 400;
  margin-bottom: 0px;
  font-size: 1rem
}

.menu-icon {
  color: #fff;
}


.savor-text {
  font-family: "Kite One";
  font-size: 2.5rem;
  letter-spacing: 2px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  margin-bottom: 0px;
}

@media screen and (max-width: 1024px) {
  .savor-text {
    font-size: 1.6rem;
  }

  .deliver-text {
    font-size: 0.7rem
  }
}

@media screen and (max-width:768px) {
  .savor-text {
    font-size: 1.5rem;
  }

  .deliver-text {
    font-size: 0.7rem
  }
}

@media screen and (max-width:425px) {
  .number-container {
    flex-direction: column;
    padding: 1rem 0;
  }
}

.phone-container {
  display: flex !important;
  align-items: center;
}

.banner-section {
  background-image: url('../../../assets/images/banner1.png');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  position: relative;
  z-index: 0;
}

.navbar-container {
  background-color: rgba(254, 185, 5, 0.33);
  width: 100%;
  padding: 0.5rem;
  z-index: 1;
  position: absolute;
}

.navbar-container .navbar {
  padding: 0;
}

.navbar-toggler-container {
  display: none;
}

.navbar-toggler {
  border: 2px solid #fff !important;

  background-color: #221B4B !important;
}

.navbar-toggler .navbar-toggler-icon,
.navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 0, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

/* .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 0, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M6 6L24 24M6 24L24 6'/%3E%3C/svg%3E") !important;
} */

header {
  position: revert !important;
}

@media screen and (max-width:768px) {
  header {
    position: absolute !important;
  }

  .header-container {
    display: none;
  }

  .menu-for-mobile {
    display: block;
  }

  .header-container-mobile {
    display: inline-block;
  }

  .navbar-container {
    background-color: #000;
    padding: 0;
    position: fixed;
    /* top: 64px; */
  }

  .navbar {
    background-color: #000;
  }

  .cart-counter {
    font-size: 1rem;
  }

  .navbar-toggler-container {
    display: flex;
    position: fixed;
    top: 0rem;
    width: 100%;
    z-index: 999;
    padding: 0 1rem;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
  }

  body {
    margin-top: 50px !important;
  }
}

.search-section {
  position: relative;
}

.search-container {
  background-color: #333638;
  padding: 4rem !important;
}


.search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #ccc;
}

.search-icon-input {
  width: 100% !important;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  padding-left: 2.5rem !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  background-color: #333638 !important;
}

.taste-of-royalty {
  font-family: "Kite One";
  font-size: 1.87rem;
  text-align: center;
  margin-top: 1rem;
  color: #fff;
  letter-spacing: 1px;
  z-index: 0;
  font-style: italic;
}

.welcome-text {
  font-size: 4.3rem;
  text-align: center;
  color: #fff;
  font-family: "Kaushan Script";
  font-weight: 400;
  font-style: italic;
  z-index: 0;
}

.welcome-text-description {
  font-family: "Kite One";
  font-size: 1.1rem;
  font-style: normal;
  text-align: center;
  word-spacing: 0.5px;
  letter-spacing: 1.5px;
  color: #fff;
  z-index: 0;
}



@media screen and (max-width:1024px) {
  .welcome-text {
    font-size: 5rem;
  }
}

@media screen and (max-width:768px) {
  .welcome-text {
    font-size: 3rem;
  }
}

@media screen and (max-width:425px) {
  .welcome-text {
    font-size: 1.9rem;
  }

  .taste-of-royalty {
    font-size: 1.2rem;
  }

  .welcome-text-description {
    font-size: 1rem;
  }
}

.layers-section {
  width: 100%;
  position: relative;
}

.layers-section div {
  position: absolute;
  right: 0;
  top: -10rem;
  z-index: 1
}

.layers-section-image {
  width: 33rem;
  height: 26rem
}

@media screen and (max-width:1024px) {
  .layers-section-image {
    width: 28rem;
    height: 21rem;
  }
}

.flavour-section {
  background-image: url("../../../assets/images/flavours.png");
  background-size: cover;
  background-position: top;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 1);
}

.order-online-link {
  position: absolute;
  top: -30%;
  right: 0;
  width: 18rem;
  height: 15rem;
}

.flavour-image {
  width: 100%;
  height: 100%;
  margin-left: auto;
}

.flavours-text {
  font-family: "Lemon";
  font-style: normal;
  font-size: 5.6rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke-width: 5px;
  -webkit-text-stroke-color: #fff;
  line-height: 4rem;
  z-index: 0;
}

.flavours-text-2 {
  color: #FFD542;
  font-size: 4.3rem;
  font-family: "Oleo Script", system-ui;
  font-weight: 400;
  font-style: normal;
  z-index: 0;
}


@media screen and (max-width:1440px) {
  .flavours-text {
    font-size: 4.3rem;
    line-height: 4rem;
  }

  .flavours-text-2 {
    font-size: 3.5rem;
  }
}

@media screen and (max-width:1024px) {
  .flavours-text {
    font-size: 4.3rem;
  }

  .flavours-text-2 {
    font-size: 3.5rem;
  }
}

@media screen and (max-width:768px) {
  .flavours-text {
    font-size: 3.5rem;
  }

  .order-online-link {
    width: 12rem;
    height: 12rem;
  }

  .flavours-text-2 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width:425px) {
  .flavours-text {
    text-align: center;
    -webkit-text-stroke-width: 2px;
  }

  .order-online-link {
    top: -15%;
    width: 13rem;
    height: 12rem;
  }

  .flavours-text-2 {
    text-align: center;
  }
}

.our-food-section {
  height: 48rem;
  width: 100%;
  position: relative;
  background-color: #F5F5F5;
}

.our-food-section .food-container {
  padding: 3rem 3rem 3rem 3rem;
  align-items: center;
}

.our-text {
  font-family: "Lily Script One, system-ui";
  font-weight: 400;
  font-size: 10rem;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000;
  line-height: 8rem;
}

.food-menu-text {
  font-family: "Lemon, serif";
  font-size: 7.5rem;
  font-weight: 1000;
  color: #FFD542;
  line-height: 8rem;
}

.our-food-description {
  font-family: "Kite One";
  font-size: 1.18rem;
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
}

.chicken-image {
  width: 100%;
  height: 35rem;
}

@media screen and (max-width:1024px) {
  .our-text {
    font-size: 7rem;
  }

  .food-menu-text {
    font-size: 5rem;
  }

  .chicken-image {
    height: 28rem;
  }
}

@media screen and (max-width:768px) {
  .our-food-section {
    height: 75rem;
  }
}

.delicious-bites-section {
  background-image: url("../../../assets/images/delicious_bites.png");
  background-size: cover;
  background-position: top;
  height: 60rem;
  width: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 1);
  padding-left: 2rem;

}

.hand-image {
  width: 100%;
  height: 50rem;
  position: relative;
  transform: translate(0px, -30%);
}

.delicious-bites-text {
  font-family: "Lemon, serif";
  font-weight: 400;
  font-size: 7.5rem;
  font-weight: 600;
  color: #000;
  line-height: 8rem;
  transform: rotate(-34.09deg);
}

.delicious-bites-text span {
  color: #fff;
  font-size: 5.6rem;
}

.order-now-button {
  width: 25rem;
  transform: translate(25rem, -3rem)
}

@media screen and (max-width:1440px) {
  .hand-image {
    transform: translate(0px, -78%);
    height: 40rem;
  }
}

@media screen and (max-width:1024px) {
  .hand-image {
    transform: translate(0px, -65%);
    height: 35rem;
  }

  .delicious-bites-text {
    font-size: 4.5rem;
  }

  .delicious-bites-text span {
    font-size: 3.5rem;
  }

  .order-now-button {
    width: 20rem;
    transform: translate(12rem, -3rem)
  }
}

@media screen and (max-width:768px) {
  .hand-image {
    transform: translate(0px, -65%);
    height: 30rem;
  }

  .delicious-bites-section {
    height: 50rem;
  }

  .delicious-bites-text {
    font-size: 3rem;
    line-height: 6rem;
  }

  .delicious-bites-text span {
    font-size: 2rem;
  }

  .order-now-button {
    width: 15rem;
    transform: translate(7.5rem, -3rem)
  }
}

.yummy-section {
  background-image: url("../../../assets/images/yummy.png");
  height: 45rem;
  width: 100%;
  position: relative;
  top: -10rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width:1440px) {
  .yummy-section {
    height: 35rem;
    top: -8rem;
  }
}

@media screen and (max-width:1024px) {
  .yummy-section {
    height: 23rem;
    top: -6rem;
  }
}

@media screen and (max-width:768px) {
  .yummy-section {
    height: 17rem;
    top: -6rem;
  }
}

.online-reward-section {
  width: 100%;
  background-image: url("../../../assets/images/online-reward.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.join-cafe-text {
  color: #fff;
  font-size: 1.5rem;
  font-family: "Lato";
  font-weight: 700;
  letter-spacing: 1.5px;
  word-spacing: 0.5px;
  font-style: normal;
  z-index: 0;
}

.join-cafe-text-2 {
  color: #fff;
  font-size: 0.875rem;
  font-family: "Kite One";
  letter-spacing: 1.5px;
  word-spacing: 0.5px;
  z-index: 0;
}

.online-text {
  color: rgb(254, 185, 5);
  font-size: 3.1rem;
  font-family: "Kite One";
  transform: rotate(-15deg);
  line-height: 3rem;
  text-align: center;
}

.online-text span {
  color: rgb(255, 255, 255);
  font-family: "Lily Script One", system-ui;
  font-weight: 400;
  font-size: 5.6rem;
}

.every-text {
  color: rgb(255, 255, 255);
  font-size: 3.1rem;
  font-family: "Kite One";
  transform: rotate(6deg);
  text-align: center;
  padding: 4rem 20rem 0px 1rem;
}

.onlin-text-container {
  background-image: url("../../../assets/images/online-reward-1-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.spent-text {
  color: #FEB905;
  font-size: 5.6rem;
  font-weight: 600;
}

.spent-text-2 {
  color: #fff;
  font-size: 1.6rem;
  font-family: "Kite One";
  margin-top: 1rem
}


.signup-reward-button {
  font-family: "Lemon";
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
  background-color: rgb(254, 185, 5);
  border-radius: 50px;
  padding: 0px 3rem;
  margin-top: 3rem;
  border-bottom: 6px solid rgb(255, 255, 255);
}

.points-container {
  transform: rotate(14deg);
  padding-top: 3rem;

}

@media screen and (max-width:1024px) {
  .online-text span {
    font-size: 5.5rem;
  }

  .signup-reward-button {
    font-size: 1.5rem;
  }
}

@media screen and (max-width:768px) {

  .online-text,
  .every-text,
  .points-container {
    transform: rotate(0deg);
  }

  .points-container {
    padding-top: 1rem;
  }

  .every-text {
    padding: 1rem 4rem;
  }

  .online-text span {
    font-size: 4.5rem;
  }

  .signup-reward-button {
    font-size: 1.4rem;
    border-radius: 50px;
    padding: 0px 2rem;
    border-bottom: 5px solid rgb(255, 255, 255);
  }
}

@media screen and (max-width:425px) {

  .spent-text {
    font-size: 4rem;
  }

  .online-text {
    font-size: 2.1rem;
  }

  .online-text span {
    font-size: 4rem;
  }

  .signup-reward-button {
    font-size: 1.3rem;
    border-radius: 50px;
    padding: 0px 2rem;
    border-bottom: 5px solid rgb(255, 255, 255);
  }


}

.carousel-container {
  height: 24rem;
}

.our-venus-text {
  color: rgb(255, 255, 255);
  font-family: "Luckiest Guy";
  font-weight: 400;
  font-style: normal;
  font-size: 4.3rem;
  text-align: center;
  margin-bottom: 0px;
  z-index: 0;
}

.far-wide-text {
  font-size: 1.8rem;
  color: #fff;
  font-family: "Kite One";
  text-align: center;
  z-index: 0;
}

@media screen and (max-width:1024px) {

  .our-venus-text {
    font-size: 3rem;
  }
}

@media screen and (max-width:768px) {
  .carousel-container {
    height: 18rem;
    z-index: 0;
  }

  .our-venus-text {
    font-size: 2.5rem;
  }
}

.footer-container {
  padding: 5rem 5rem
}

.app-download-img {
  width: 30px;
  height: 30px;
}

.app-download-btn {
  background-color: #FEB905;
  display: flex;
  border-radius: 25px;
  padding: 0.3rem 1.5rem 0.3rem 0.3rem;
  gap: 7px;
  margin: 0.5rem 0;
  font-family: "Kite One";
  font-size: 0.813rem;
  align-items: center;
}

@media screen and (max-width:1024px) {
  .footer-container {
    padding: 4rem;
  }
}

@media screen and (max-width:1024px) {
  .app-download-btn-container {
    display: flex;
  }

  .app-download-btn {
    font-size: 0.7rem;
  }
}

.embarace-section {
  background-image: url("../../../assets/images/embarace_love.png");
  background-size: cover;
  background-position: top;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10rem;
}

.embarace-section-container {
  background-color: #FEDB18;
  position: relative;
  padding: 1.5rem 5rem !important;
}

.stars-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 2rem;
  font-size: 2.5rem
}

.embarace-love-text {
  color: rgb(255, 255, 255);
  font-size: 3.125rem;
  font-weight: bolder;
  font-family: "Luckiest Guy", cursive;
  font-weight: 400;
  font-style: normal;
  padding-top: 5rem;
  text-align: center;
  z-index: 0;
}

.rating-image {
  position: absolute;
  width: 12rem;
  height: 12rem;
  top: -5rem;
  left: -6rem;
}

.see-reviews-btn {
  font-family: "Lemon";
  font-size: 1.25rem;
  color: #fff;
  background-color: #000;
  border-radius: 30px;
  padding: 0.51rem 3rem;
  border-bottom: 5px solid #fff;
}

@media screen and (max-width:1440px) {

  .embarace-love-text {
    font-size: 3rem;
  }
}

@media screen and (max-width:1024px) {

  .embarace-section-container {
    height: 50%;
    width: 70%;
  }

}

@media screen and (max-width:768px) {
  .rating-image {
    width: 8rem;
    height: 8rem;
    top: -4rem;
    left: -4rem;
  }



  .embarace-love-text {
    font-size: 2.5rem;
  }
}

@media screen and (max-width:425px) {

  .embarace-section-container {
    height: 50%;
    width: 70%;
    padding: 1.5rem 3rem !important;
  }

}

@media screen and (max-width:375px) {

  .embarace-section-container {
    height: 50%;
    width: 100%;
    padding: 1.5rem 2.5rem !important;
  }

}

.footer-header {
  color: #FEB905;
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 1rem;
}

.copy-right-text {
  background-color: #FEB905;
  font-size: 0.75rem;
  text-align: center;
  padding: .5rem;
  font-family: "Kite One";
  align-items: center;
  display: flex;
  justify-content: center;
}

.footer-heading {
  display: flex;
  flex-direction: column;
}

.footer-menu-container {
  display: flex;

}


@media screen and (max-width:768px) {
  .footer-menu-container {
    row-gap: 2rem;
  }
}

@media screen and (max-width:425px) {
  .footer-menu-container {
    flex-direction: column;
  }
}

@media screen and (max-width:425px) {
  .stars-container {
    font-size: 1rem
  }

  .rating-image {
    position: absolute;
    width: 10rem;
    height: 10rem;
    top: -5rem;
    left: 30%;
  }

  .embarace-love-text {
    font-size: 1.5rem;
  }
}

.checkout-section {
  text-align: center;

  position: relative;
  top: -5rem;
}

.checkout-section span {
  font-size: 1.875rem;
  color: #000;
  font-weight: 800;
  letter-spacing: 0.5rem;
  word-spacing: 0.7rem;
}

@media screen and (max-width:1024px) {
  .checkout-section span {
    letter-spacing: 0.05rem;
    word-spacing: 0.4rem;
  }
}

@media screen and (max-width:768px) {
  .checkout-section span {
    letter-spacing: 0.1rem;
  }
}