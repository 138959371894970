@import url('https://fonts.googleapis.com/css2?family=Kite+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lemon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Milonga&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lemon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oleo+Script:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lily+Script+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Khand:wght@300;400;500;600;700&display=swap');

body {
    background-image: url("../../../assets/images/body_bg_image.jpeg");
}

.modal {
    z-index: 9999 !important;
}

.modal.fade {
    background-color: rgba(0, 0, 0, 0.7);
}

#payment-form {
    padding: 20px;
    border-radius: 10px;
    background-color: #f8f9fa;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

#card-element {
    border-radius: 5px;
    background-color: white;
}

.payment-submit-button {
    display: block;
    width: 100%;
    padding: 12px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
    background-color: #0056b3;
}

.overflow-to-select-active-branch {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 1052;
    display: block;
    background: rgba(255, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.overflow-to-select-active-branch .selector-box {
    background: white;
    width: 300px;
    min-height: 200px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overflow-to-select-active-branch .selector-box h5 {
    font-size: 18px;
    margin-bottom: 20px;
}

.overflow-to-select-active-branch .selector-box select {
    min-width: 200px;
    padding: 10px;
    border-radius: 5px;
}


.flavor-rich-text-2 {
    font-family: "Lemon";
    font-size: 7.5rem;
    color: transparent;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    letter-spacing: 0.5px;
    -webkit-text-stroke-width: 5px;
    -webkit-text-stroke-color: #fff;
}

.served-with-pession-text-2 {
    font-family: "Milonga";
    font-weight: 400;
    letter-spacing: 0.5px;
    font-style: normal;
    color: #FEB905;
    text-align: center;
    font-size: 5.25rem;

}

.menu-card-wrapper {
    background-image: url("../../../assets/images/special_deals-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
}

.special-deals-text {
    font-family: "Lemon";
    font-size: 4.3rem;
    text-align: center;
    color: #212121;
    border-radius: 20px;
    background-color: rgb(248, 232, 95, 0.6);
    margin-bottom: 0;
    width: 100%;
    display: block;
    cursor: pointer;
}

.special-deals-text:hover {
    color: #212121 !important;
    ;
}

@media screen and (max-width:1440px) {
    .flavor-rich-text-2 {
        font-size: 6rem;
    }

    .served-with-pession-text-2 {
        font-size: 4rem;
    }

}

@media screen and (max-width:1024px) {
    .flavor-rich-text-2 {
        font-size: 4rem;
    }

    .served-with-pession-text-2 {
        font-size: 3rem;
    }

    .special-deals-text {
        font-size: 3rem;
    }

}

@media screen and (max-width:768px) {
    .flavor-rich-text-2 {
        font-size: 3.5rem;
        text-align: center;
    }

    .served-with-pession-text-2 {
        font-size: 2.5rem;
        text-align: center;
    }

    .special-deals-text {
        font-size: 2rem;
    }

}

@media screen and (max-width:425px) {
    .flavor-rich-text-2 {
        font-size: 2.5rem;
        text-align: center;
        -webkit-text-stroke-width: 2px;
    }

    .served-with-pession-text-2 {
        font-size: 1.5rem;
        text-align: center;
    }

    .special-deals-text {
        font-size: 1.5rem;
    }

}



.footer-heading a,
.footer-heading p {
    color: #fff;
    font-variant: all-small-caps;
    font-size: 1.3rem;
    font-family: "Kite One";
    padding: .01rem;
    margin-bottom: 0px;
    z-index: 0;
}

.StripeElement {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.kh-floating-cart {
    background-color: #FEB905 !important;
}

.kh-floating-cart__text {
    color: #000 !important;
}

.cart {
    position: relative;
}

.cart-counter {
    background-color: rgba(254, 185, 5, 0.8);
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    padding: 0.2rem 0.6rem;
    border-radius: 50%;
}

.catering-section {
    background-image: url('../../../assets/images/bg_image.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 3.5rem !important;
}


@media screen and (max-width:1024px) {
    .celebration-text {
        font-size: 3.7rem;
        width: fit-content;
    }
}

@media screen and (max-width:768px) {
    .celebration-text {
        font-size: 3.7rem;
        width: fit-content;
    }
}


.overlay-text {
    position: absolute;
    left: 6rem;
    top: 50%;
    transform: translateY(-50%) rotate(-10deg) scale(1.1);
    /* Rotates the text */
    font-weight: bold;
}

.custom-text {
    color: #fff;
    font-size: 1.87rem;
    font-weight: 400;
    font-family: "Kite One";
    text-transform: none;
}


.unique {
    color: #FEB905;
    font-size: 4rem;
    font-weight: 600;
}

.special {
    color: #fff;
    font-size: 4.3rem;
    font-family: "Lily Script One";
    font-weight: 400;
    font-style: normal;
}

@media screen and (max-width:1024px) {
    .overlay-text {
        transform: translate(-22%, -50%) rotate(-20deg) scale(1);
    }

    .catering-section {
        padding: 3rem;
    }
}

@media screen and (max-width:768px) {
    .overlay-text {
        position: unset;
        width: fit-content;
        transform: translate(40%, -10%) rotate(-20deg) scale(1.1);
    }

    .custom-text {
        font-size: 2rem;
    }

    .unique {
        font-size: 3rem;
    }

    .special {
        font-size: 3.1rem;
    }
}

@media screen and (max-width:425px) {

    .overlay-text {
        position: unset;
        transform: translate(0%, -12%) rotate(-20deg);
        width: fit-content;
    }

    .custom-text {
        font-size: 2rem;
    }

    .unique {
        font-size: 3rem;
    }

    .special {
        font-size: 3.1rem;
    }
}


.contact-info strong {
    color: #FEDB18;
}

.creamy-section {
    background-image: url('../../../assets/images/creamy.png');
    background-size: cover;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    color: white;
    height: 35rem;
    background-color: rgba(0, 0, 0, .6);
}


.card-container {
    display: flex;
    justify-content: space-around;
    align-items: end;
    flex-wrap: wrap;
    row-gap: 10px;
}


.card-custom-text {
    text-align: center;
    font-family: 'Lemon';
    color: #fff;
    font-size: 1.125rem;
}

@media screen and (max-width:768px) {
    .card-custom-text {
        font-size: 1.2rem;
    }

    .card-container {
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

}

.header-container {
    background-color: #000;
    padding: 0 5rem;
}

@media screen and (max-width:1024px) {
    .header-container {
        padding: 0;
    }
}

.navbar-logo {
    width: 140px;
    display: inline-block;
    transition: all 0.3s ease;
    text-decoration: none;
}

@media screen and (max-width: 576px) {
    .navbar-logo {
        width: 50%;
        margin: auto;
    }
}


.deliver-image {
    width: 2.063rem;
    height: 2.063rem;
    padding-right: 0.5rem;
}


.menu-icon {
    color: #fff;
}


@media screen and (max-width:425px) {
    .number-container {
        flex-direction: column;
        padding: 1rem 0;
    }
}

.phone-container {
    display: flex;
    align-items: center;
}

.search-section {
    position: relative;
}

.search-container2 {
    background-color: #fff;
    padding: 4rem !important;
}


.search-icon-2 {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #333638;
}

.search-icon-input-2 {
    width: 100% !important;
    border: none !important;
    border-bottom: 1px solid #333638 !important;
    padding-left: 2.5rem !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    background-color: #fff !important;
}

.food-items-text {
    font-family: "Kite One";
    font-size: 1.87rem;
    text-align: center;
    margin-top: 1.5rem;
    color: #333638;
    z-index: 0;
    letter-spacing: 1.5px;
    font-style: italic;
}

.popular-menu-text {
    font-size: 4.3rem;
    text-align: center;
    color: #333638;
    font-family: "Kaushan Script";
    font-weight: 400;
    z-index: 0;
    font-style: italic;
}

@media screen and (max-width:1024px) {
    .popular-menu-text {
        font-size: 5rem;
    }
}

@media screen and (max-width:768px) {
    .popular-menu-text {
        font-size: 3rem;
    }
}

@media screen and (max-width:425px) {
    .food-items-text {
        font-size: 1.2rem;
    }

    .popular-menu-text {
        font-size: 2rem;
    }
}

.layers-section {
    width: 100%;
    position: relative;
}

.layers-section div {
    position: absolute;
    right: 0;
    top: -10rem;
    z-index: 1
}

.layers-section-image {
    width: 33rem;
    height: 26rem
}

.login-btn {
    border: none;
    border-radius: 30px;
    padding: 0.72rem 1.95rem;
    margin-left: 0.5rem;
}


@media screen and (max-width:1024px) {
    .layers-section-image {
        width: 28rem;
        height: 21rem;
    }
}

.crunchy-section {
    background-image: url("../../../assets/images/crunchy.png");
    background-size: cover;
    width: 100%;
    height: 35rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 1);
}




@media screen and (max-width:1024px) {
    .navbar-logo {
        width: 110px;
    }

    .login-btn {
        padding: 0.32rem 1rem;
    }
}

@media screen and (max-width:768px) {
    .navbar-logo {
        width: 60px;
    }

    .deliver-image {
        width: 1.7rem;
        height: 1.7rem;
        padding-right: 0.5rem;
    }

    .login-btn {
        padding: 0.1rem 1rem;
    }
}

@media screen and (max-width:425px) {

    .navbar-logo {
        width: 140px;
    }

}

.our-food-section {
    height: 48rem;
    width: 100%;
    position: relative;
    background-color: #F5F5F5;
}

.our-food-section .food-container {
    padding: 3rem 3rem 3rem 3rem;
    align-items: center;
}

.our-text {
    font-family: "Lily Script One, system-ui";
    font-weight: 400;
    font-size: 10rem;
    color: rgba(0, 0, 0, 0);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #000;
    line-height: 8rem;
}

.food-menu-text {
    font-family: "Lemon, serif";
    font-size: 7.5rem;
    font-weight: 1000;
    color: #FFD542;
    line-height: 8rem;
}

.our-food-description {
    font-family: "Kite One";
    font-size: 1.18rem;
    font-style: normal;
    text-align: center;
    letter-spacing: 1px;
}

.chicken-image {
    width: 100%;
    height: 35rem;
}

@media screen and (max-width:1024px) {
    .our-text {
        font-size: 7rem;
    }

    .food-menu-text {
        font-size: 5rem;
    }

    .chicken-image {
        height: 28rem;
    }
}

@media screen and (max-width:768px) {
    .our-food-section {
        height: 75rem;
    }
}

.delicious-bites-section {
    background-image: url("../../../assets/images/delicious_bites.png");
    background-size: cover;
    background-position: top;
    height: 60rem;
    width: 100%;
    position: relative;
    background-color: rgba(0, 0, 0, 1);
    padding-left: 2rem;

}

.hand-image {
    width: 100%;
    height: 50rem;
    position: relative;
    transform: translate(0px, -30%);
}

.delicious-bites-text {
    font-family: "Lemon, serif";
    font-weight: 400;
    font-size: 7.5rem;
    font-weight: 600;
    color: #000;
    line-height: 8rem;
    transform: rotate(-34.09deg);
}

.delicious-bites-text span {
    color: #fff;
    font-size: 5.6rem;
}

.order-now-button {
    width: 25rem;
    transform: translate(25rem, -3rem)
}

@media screen and (max-width:1440px) {
    .hand-image {
        transform: translate(0px, -78%);
        height: 40rem;
    }
}

@media screen and (max-width:1024px) {
    .hand-image {
        transform: translate(0px, -65%);
        height: 35rem;
    }

    .delicious-bites-text {
        font-size: 4.5rem;
    }

    .delicious-bites-text span {
        font-size: 3.5rem;
    }

    .order-now-button {
        width: 20rem;
        transform: translate(12rem, -3rem)
    }
}

@media screen and (max-width:768px) {
    .hand-image {
        transform: translate(0px, -65%);
        height: 30rem;
    }

    .delicious-bites-section {
        height: 50rem;
    }

    .delicious-bites-text {
        font-size: 3rem;
        line-height: 6rem;
    }

    .delicious-bites-text span {
        font-size: 2rem;
    }

    .order-now-button {
        width: 15rem;
        transform: translate(7.5rem, -3rem)
    }
}

.yummy-section {
    background-image: url("../../../assets/images/yummy.png");
    height: 45rem;
    width: 100%;
    position: relative;
    top: -10rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@media screen and (max-width:1440px) {
    .yummy-section {
        height: 35rem;
        top: -8rem;
    }
}

@media screen and (max-width:1024px) {
    .yummy-section {
        height: 23rem;
        top: -6rem;
    }
}

@media screen and (max-width:768px) {
    .yummy-section {
        height: 17rem;
        top: -6rem;
    }
}

.biryani-section {
    width: 100%;
    background-image: url("../../../assets/images/biryani.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 35rem;
}



.every-text {
    color: rgb(255, 255, 255);
    font-size: 3.1rem;
    font-family: "Kite One";
    transform: rotate(6deg);
    text-align: center;
    padding: 4rem 20rem 0px 1rem;
}

.onlin-text-container {
    background-image: url("../../../assets/images/online-reward-1-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.spent-text {
    color: #FEB905;
    font-size: 5.6rem;
    font-weight: 600;
}

.spent-text-2 {
    color: #fff;
    font-size: 1.6rem;
    font-family: "Kite One";
    margin-top: 1rem
}


.signup-reward-button {
    font-family: "Lemon";
    font-size: 1.5rem;
    color: rgb(0, 0, 0);
    background-color: rgb(254, 185, 5);
    border-radius: 50px;
    padding: 0px 3rem;
    margin-top: 3rem;
    border-bottom: 6px solid rgb(255, 255, 255);
}

.points-container {
    transform: rotate(14deg);
    padding-top: 3rem;

}

@media screen and (max-width:1024px) {
    .signup-reward-button {
        font-size: 1.5rem;
    }
}

@media screen and (max-width:768px) {

    .every-text,
    .points-container {
        transform: rotate(0deg);
    }

    .points-container {
        padding-top: 1rem;
    }

    .every-text {
        padding: 1rem 4rem;
    }

    .signup-reward-button {
        font-size: 1.4rem;
        border-radius: 50px;
        padding: 0px 2rem;
        border-bottom: 5px solid rgb(255, 255, 255);
    }
}

@media screen and (max-width:425px) {

    .spent-text {
        font-size: 4rem;
    }

    .signup-reward-button {
        font-size: 1.3rem;
        border-radius: 50px;
        padding: 0px 2rem;
        border-bottom: 5px solid rgb(255, 255, 255);
    }

    .search-container2 {
        padding: 1rem !important;
    }

}

.far-wide-text {
    font-size: 1.8rem;
    color: #fff;
    font-family: "Kite One";
    text-align: center;
}


.footer-container {
    padding: 5rem 5rem
}

.app-download-img {
    width: 30px;
    height: 30px;
}

.app-download-btn {
    background-color: #FEB905;
    display: flex;
    border-radius: 25px;
    padding: 0.3rem 1.5rem 0.3rem 0.3rem;
    gap: 7px;
    margin: 0.5rem 0;
    font-family: "Kite One";
    font-size: 0.813rem;
    align-items: center;
}

@media screen and (max-width:1024px) {
    .footer-container {
        padding: 4rem;
    }
}

@media screen and (max-width:1024px) {
    .app-download-btn-container {
        display: flex;
    }

    .app-download-btn {
        font-size: 0.7rem;
    }
}


.stars-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 2rem;
    font-size: 2.5rem
}

.embarace-love-text {
    color: rgb(255, 255, 255);
    font-size: 3.125rem;
    font-weight: bolder;
    font-family: "Luckiest Guy", cursive;
    font-weight: 400;
    font-style: normal;
    padding-top: 5rem;
    text-align: center;
}

.rating-image {
    position: absolute;
    width: 12rem;
    height: 12rem;
    top: -5rem;
    left: -6rem;
}

.see-reviews-btn {
    font-family: "Lemon";
    font-size: 1.25rem;
    color: #fff;
    background-color: #000;
    border-radius: 30px;
    padding: 0.51rem 3rem;
    border-bottom: 5px solid #fff;
}

@media screen and (max-width:1440px) {

    .embarace-love-text {
        font-size: 3rem;
    }
}


@media screen and (max-width:768px) {
    .rating-image {
        width: 8rem;
        height: 8rem;
        top: -4rem;
        left: -4rem;
    }

    .embarace-love-text {
        font-size: 2.5rem;
    }
}

.footer-header {
    color: #FEB905;
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 1rem;
}

.footer-heading {
    display: flex;
    flex-direction: column;
}

.footer-menu-container {
    display: flex;

}


@media screen and (max-width:768px) {
    .footer-menu-container {
        row-gap: 2rem;
    }
}

@media screen and (max-width:425px) {
    .footer-menu-container {
        flex-direction: column;
    }
}

@media screen and (max-width:425px) {
    .stars-container {
        font-size: 1rem
    }

    .rating-image {
        position: absolute;
        width: 10rem;
        height: 10rem;
        top: -5rem;
        left: 30%;
    }

    .embarace-love-text {
        font-size: 1.5rem;
    }
}

.checkout-section {
    text-align: center;

    position: relative;
    top: -5rem;
}

.checkout-section span {
    font-size: 1.875rem;
    color: #000;
    font-weight: 800;
    letter-spacing: 0.5rem;
    word-spacing: 0.7rem;
}

.postcode-text {
    color: #fff;
    font-size: 24px !important;
    margin-bottom: 1rem;
    font-family: Langdon !important;
}

.postcode-text span {
    color: #f3d700;
}

.formContainer {

    border-radius: 10px;
    padding: 7rem 1rem 1rem 1rem;
    text-align: center;
    width: 50%;
    height: 100%;
    margin: auto;
}

@media screen and (max-width:1024px) {
    .checkout-section span {
        letter-spacing: 0.05rem;
        word-spacing: 0.4rem;
    }

    .formContainer {
        width: 70%;
    }

    .postcode-text {
        font-size: 16px !important;
    }
}

@media screen and (max-width:768px) {
    .checkout-section span {
        letter-spacing: 0.1rem;
    }

    .formContainer {
        width: 90%;
    }

    .postcode-text {
        font-size: 15px !important;
    }
}


.sizeAvatar {
    width: 30px;
    height: 30px;
    display: inline-flex;
    cursor: pointer;
    background-color: #5DCEFF;
}

.userNamePlan {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    margin-left: 15px;
    color: #fff;
}

.menuItem {
    padding: 6px 24px;
    font-weight: 400;
    font-size: 0.8125rem;
    color: #000;
}

.address-field1 {
    font-size: 1.8em;
    background-color: #fff !important;
    color: #333333 !important;
    border-radius: 25px !important;
    border: none !important;
}

.helperText {
    color: red;
    text-align: left;
    background-color: #FAA0A0;
    border-radius: 25px;
    padding: 0.25rem 1rem;
}

.address-field:hover,
.address-field:focus,
.address-field:active {
    border: "none"
}

@keyframes buttonBounce {

    15%,
    40%,
    75%,
    100% {
        transform-origin: 50% 50%;
    }

    15% {
        transform: scale(1.1, 1.03);
    }

    40% {
        transform: scale(0.9, .9);
    }

    75% {
        transform: scale(1.03, 1);
    }

    100% {
        transform: scale(1, 1);
    }
}

.find-food-btn {
    background-color: #00BFFF !important;
    color: #fff !important;
    margin-top: 1rem !important;
    border-radius: 25px !important;

}

.find-food-btn:hover {
    background-color: #007FFF;
    animation: buttonBounce 0.4s linear;
}

.address-field {
    font-size: 1rem;
    height: 50px;
    padding: 0;
    padding-left: 18px !important;
    width: 100%;
    margin-bottom: 1rem;
}